import { useEffect, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { getCollectionDatesFromExistingOrders } from "../../utils/api";
import { Calendar } from "@/components/ui/calendar";

const AvailableDates = ({
	selectedDate,
	setSelectedDate,
}: {
	setSelectedDate: (date: string) => void;
	selectedDate: string;
}) => {
	const { setIsLoading, loggedInUserToken, loggedInUser } = useStateContext()!;
	const [existingOrders, setExistingOrders] = useState<string[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (loggedInUser) {
					const exisitingOrders: string[] =
						await getCollectionDatesFromExistingOrders(loggedInUserToken);
					setExistingOrders(exisitingOrders);
				}

				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	const handleDateSelect = (date: Date | undefined) => {
		if (date) {
			const selectedDate = new Date(date);
			selectedDate.setHours(10, 0, 0, 0);
			setSelectedDate(selectedDate.toISOString());
		}
	};

	const isDateWithinBlockoutRange = (
		date: Date,
		startDate: Date,
		endDate: Date,
	) => {
		return date >= startDate && date <= endDate;
	};

	// TODO: Change date every year
	// const blockoutStartDate = new Date('2024-08-12');
	// const blockoutEndDate = new Date('2024-08-28');
	const blockoutStartDate = new Date("2025-01-13");
	const blockoutEndDate = new Date("2025-02-01");

	const isDateDisabled = (
		date: Date,
		existingOrders: string[],
		blockoutStartDate: Date,
		blockoutEndDate: Date,
	): boolean => {
		const today = new Date();
		const threeWeeksFromToday = new Date();
		threeWeeksFromToday.setDate(today.getDate() + 21);

		const dateToCheck = new Date(date);
		dateToCheck.setHours(0, 0, 0, 0);

		const orderCount = existingOrders.filter((order) => {
			const orderDate = new Date(order);
			orderDate.setHours(0, 0, 0, 0);
			return orderDate.getTime() === dateToCheck.getTime();
		}).length;

		const isWithinBlockoutRange = isDateWithinBlockoutRange(
			date,
			blockoutStartDate,
			blockoutEndDate,
		);

		const isPastDate = date < today;
		const isAfterThreeWeeks = date > threeWeeksFromToday;

		return (
			orderCount >= 2 ||
			isWithinBlockoutRange ||
			isPastDate ||
			isAfterThreeWeeks
		);
	};

	return (
		<div
			className={`rounded-lg p-4 mt-4 flex flex-col xl:flex-row justify-center items-center gap-10 xl:gap-20 bg-base-100`}
		>
			<div>
				<h2 className="text-lg font-bold">When do you want us to collect?</h2>
				<p className="italic text-sm mt-1">
					{`We will collect anytime between 9am and 9pm on the day - we can't specify a specific time window.`}
				</p>
			</div>

			<Calendar
				mode="single"
				selected={new Date(selectedDate)}
				onSelect={handleDateSelect}
				disabled={(date) =>
					isDateDisabled(
						date,
						existingOrders,
						blockoutStartDate,
						blockoutEndDate,
					)
				}
				className="rounded-md w-full"
				weekStartsOn={1}
			/>
		</div>
	);
};

export default AvailableDates;
